<template>
	<edit-template id="work-add" style="padding-top: 70rem" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="确定" cancelText="取消">

    <el-form ref="form" :model="form" :rules="rules" class="form" style="min-height: calc(100vh - 294rem)" label-width="150rem">
      <el-form-item prop="class_name" label="课程名称">
        <el-input v-model="form.class_name" maxlength="50" placeholder="请输入课程名称"></el-input>
      </el-form-item>
      <el-form-item label="行课类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择行课类型">
          <el-option v-for="item in classType" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校区" prop="school_id">
        <el-select v-model="form.school_id" placeholder="请选择校区" @change="schoolChange">
          <el-option v-for="item in schoolOptions" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课日期" prop="date">
        <el-date-picker v-model="form.date" :picker-options="timePicker" type="date" :clearable="false" value-format="yyyy-MM-dd" @change="timeChange" placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <div class="flex">
        <el-form-item label="上课时间" prop="start_section">
          <el-select v-model="form.start_section" @change="timeStartChange" :disabled="!(form.school_id && form.date)" placeholder="请选择开始课节">
            <el-option v-for="item in startOption" :label="item.section_cn" :value="item.section" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <div style="margin-top: 10rem;margin-left: 12rem">
          至
        </div>
        <el-form-item label="" label-width="20rem" prop="end_section">
          <el-select v-model="form.end_section" @change="timeEndChange" :disabled="!form.start_section" placeholder="请选择结束课节">
            <el-option v-for="item in endOption" :label="item.section_cn" :value="item.section" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="上课教师" prop="teacher">
        <el-input v-model="form.teacher" maxlength="50" placeholder="" style="display: none"></el-input>
        <el-button @click="dialogOpen" :disabled="!form.end_section">请选择上课老师</el-button>
        <div>
          <el-tag v-for="(item, index) in teacherList" :key="item.clerk_name" @close="tagClose(index, item.id)" style="margin-right: 10rem" closable="">
            {{ item.clerk_name }}
          </el-tag>
        </div>
      </el-form-item>
    </el-form>



    <el-dialog :visible.sync="teacherDialogShow" width="60%" @close="dialogClose">
      <div>
        <div class="flex flex-align">
          <el-input size="small" placeholder="请输入老师关键字" v-model="teacherKeyword">
            <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer" @click="onSearch"></i>
          </el-input>
          <el-button size="small" @click="onClear" style="margin-left: 20rem">清空</el-button>
          <el-button size="small" type="primary" :disabled="!multipleSelection.length" @click="onCheck">选择</el-button>
        </div>
        <div>
          <el-table max-height="500" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" empty-text="暂无符合时间的老师" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column v-for="item in tableConfig" :prop="item.prop" :label="item.label">
              <!--              <template slot-scope="scope">{{ scope.row.date }}</template>-->
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!--      <t-result v-if="!historyList.length" :type="'empty'"></t-result>-->
    </el-dialog>


</edit-template>
</template>

<script>
import datePicker from "@/components/DatePicker"
import moment from "moment";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    let validateDate = (rule, value, callback) => {
      if (!this.date && this.course.length === 0) return callback(new Error("请选择代课时间及课节"))
      if (!this.date) callback(new Error("请选择代课时间"));
      else if (this.course.length === 0) callback(new Error("请选择代课课节"))
      else callback();
    }
    return {
      loading: false,
      teacherDialogShow: false,
      form: {
        class_name: '',
        school_id: null,
        date: null,
        start_section: null,
        end_section: null,
        teacher: '',
        type: '',
      },
      params: {
        start_at: '',
        end_at: '',
        section: [],
        teachers: []
      },
      schoolOptions: [],
      startOption: [],
      endOption: [],
      classType: [],

      timePicker: {
        disabledDate(time) {
          return time.getTime() < moment().format('x') - 3600 * 24 * 1000
        }
      },
      rules: {
        class_name: [{required: true, message: "请输入课程名称"}],
        school_id: [{required: true, message: "请选择校区"}],
        date: [{required: true, message: "请选择上课日期"}],
        start_section: [{required: true, message: "请选择开始课节"}],
        end_section: [{required: true, message: "请选择结束课节"}],
        teacher: [{required: true, message: "请选择上课教师"}],
        type: [{required: true, message: "请选择行课类型"}],
      },

      teacherKeyword: '',
      tableData: [],
      tableConfig: [
        {label: '姓名', prop: 'clerk_name'},
        {label: '学科', prop: 'subject_name'},
      ],
      multipleSelection: [],
      teacherList: [],
    }
  },
  mounted() {
    this.getSchool()
  },
  components: {
    datePicker
  },
  methods: {
    getSchool() {
      this.$_axios.get('site/school').then(res => {
        this.schoolOptions = res.data.data
      })
    },

    schoolChange() {
      this.getSection()
    },

    timeChange() {
      this.getSection()
    },

    getSection() {
      if (!this.form.school_id || !this.form.date) return;
      this.startOption = [];
      this.endOption = [];
      this.tableData = [];
      this.form.start_section = null;
      this.form.end_section = null;
      this.form.teacher = '';
      this.teacherList = []
      let params = {
        school_id: this.form.school_id,
        date: this.form.date
      };
      this.$_axios2('api/attendance/administrative-class-judge/sections', {params}).then(res => {
        // console.log(res)
        if (!Array.isArray(res.data)) {
          let option = res.data.data;
          if (moment(this.form.date).format('yyyy-MM-dd') === moment().format('yyyy-MM-dd')) {
            this.startOption = option.map(item => {
              if (moment(this.form.date + ' ' + item.start_time).format('x') < moment().format('x')) {
                return {...item, disabled: true}
              } else {
                return item
              }
            })
          } else {
            this.startOption = option
          }
        }
      })
    },

    timeStartChange() {
      this.endOption = [];
      this.tableData = [];
      this.form.end_section = null;
      this.form.teacher = '';
      this.teacherList = [];
      let start_section = this.form.start_section,
          startOptions = this.startOption;
      let boo = true;
      this.endOption = startOptions.map(item => {
        if (item.section != start_section && boo) {
          return {...item, disabled: true}
        } else {
          if (item.section == start_section) boo = false;
          return item
        }
      })
    },

    timeEndChange() {
      this.tableData = [];
      this.form.teacher = '';
      this.teacherList = [];
      this.params.section = [];
      let start = this.form.start_section,
          end = this.form.end_section,
          boo = false;
      this.startOption.forEach(item => {
        if (start === end) {
          if (item.section == start) {
            this.params.section.push(item.section)
            this.params.start_at = item.start_time
            this.params.end_at = item.end_time
          }
        } else {
          if (item.section == start) {
            boo = true;
            this.params.section.push(item.section)
            this.params.start_at = item.start_time
          } else if (item.section == end) {
            boo = false;
            this.params.section.push(item.section)
            this.params.end_at = item.end_time
          } else if (boo) {
            this.params.section.push(item.section)
          }
        }
      })
      this.onSearch();
    },

    onSearch(boo = true) {
      let params = {
        clerk_name: this.teacherKeyword,
        school_id: this.form.school_id,
        date: this.form.date,
        start_at: this.params.start_at,
        end_at: this.params.end_at,
      }
      this.$_axios2('api/attendance/administrative-class-judge/candidate', {params}).then(res => {
        // console.log(res)
        if (!Array.isArray(res.data)) {
          this.tableData = res.data.data;
          if (boo) {
            let arr = this.teacherList.map(item => item.id)
            this.$nextTick(() => {
              this.tableData.forEach(item => {
                if (arr.includes(item.id)) {
                  this.$refs.multipleTable.toggleRowSelection(item);
                }
              })
            })
          }
        }
      })
    },

    onClear() {
      this.$refs.multipleTable.clearSelection();
      this.teacherKeyword = '';
      this.onSearch(false)
    },

    onCheck() {
      this.teacherList = [];
      this.form.teacher = '';
      this.params.teachers = [];
      let multipleSelection = this.multipleSelection;
      if (multipleSelection.length) {
        multipleSelection.forEach(item => {
          this.teacherList.push(item)
          this.form.teacher = 1
          this.params.teachers.push(item.id)
        })
      }
      this.teacherDialogShow = false
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    dialogOpen() {
      this.teacherDialogShow = true;
      let arr = this.teacherList.map(item => item.id)
      this.$nextTick(() => {
        this.tableData.forEach(item => {
          if (arr.includes(item.id)) {
            this.$refs.multipleTable.toggleRowSelection(item);
          }
        })
      })
    },

    dialogClose() {
      this.$refs.multipleTable.clearSelection();
    },

    tagClose(e, id) {
      this.teacherList.splice(e, 1)
      this.params.teachers.splice(e, 1)
      this.multipleSelection.splice(e, 1)
    },

    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            ...this.params,
            class_name: this.form.class_name,
            school_id: this.form.school_id,
            date: this.form.date,
            type: this.form.type,
          };
          this.$_axios2.post('api/attendance/administrative-class-judge/create', params).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '新增成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          })

        }
      })
    }
  },

  created() {
    this.$_axios2.post('api/attendance/administrative-class-judge/type-options').then(res => {
      this.classType = res.data.data
    })
  }
}
</script>

<style scoped lang="scss">
#work-add {
  padding-top: 35rem;

  .form {

    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    .el-form-item {
      margin-bottom: 30rem;
    }
  }

  .el-checkbox {
    height: 80rem;
    margin: 0 0 10rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150rem;

    ::v-deep span.el-checkbox__input {
      display: none;
    }
  }

  ::v-deep .el-checkbox.is-checked {
    //background-color: #1d2088;

    .el-checkbox__label {
      color: white;
    }
  }

  .course {
    //text-align: center;
    line-height: 30rem;
    max-height: 353rem;
    width: 100%;
    overflow-y: scroll;
    padding-top: 20rem;

    p {
      line-height: 24rem;
    }

    li {
      padding: 10rem 5rem;
      border-bottom: 1rem solid #eeeeee;
      cursor: pointer;
    }

    li:last-child {
      border-bottom: none;
    }

    li:hover {
      background-color: #eeeeee;
    }

    li.courseActive {
      background-color: #1d2088;
      color: white;
    }
  }

}
</style>
